import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import useRecipeItem from '@/modules/cms/composables/recipes/useRecipeItem';

const useRecipeState = () => {
  const store = useStore();

  const stateMsg = ref('');
  const stateMsgStatus = ref('form-');
  const showMsg = ref(false);

  const { selectedRecipe } = useRecipeItem();

  const updateRecipeStatus = async () => {
    showMsg.value = false;

    const { status, msg, recipeID } = await store.dispatch(
      'cmsRecipes/updateRecipeStatus'
    );
    stateMsg.value = msg;
    stateMsgStatus.value += status ? 'success' : 'error';
    showMsg.value = true;

    setTimeout(() => {
      showMsg.value = false;
      stateMsg.value = '';
      stateMsgStatus.value = 'form-';

      if (!status) {
        selectedRecipe(recipeID);
      }
    }, 2500);
  };

  return {
    recipeStatus: computed(
      () => store.getters['cmsRecipes/getRecipeSelectedState']
    ),
    updateRecipeStatus,
    showMsg,
    stateMsg,
    stateMsgStatus,
  };
};

export default useRecipeState;
