<template>
  <div class="mb-1 form-cms">
    <Subtitle subtitle="Estado" />
    <div class="fields-flex mt-1">
      <div class="form-control form-radio">
        <label>Revisado</label>
        <input
          type="radio"
          value="SI"
          name="state-recipe"
          :defaultChecked="recipeStatus === 'SI'"
          @change="updateRecipeStatus"
        />
      </div>
      <div class="form-control form-radio">
        <label>Sin Revisar</label>
        <input
          type="radio"
          value="NO"
          name="state-recipe"
          :defaultChecked="recipeStatus === 'NO'"
          @change="updateRecipeStatus"
        />
      </div>
    </div>
  </div>
  <Message v-if="showMsg" :message="stateMsg" :messageType="stateMsgStatus" />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useRecipeState from '@/modules/cms/composables/recipes/useRecipeState';

export default {
  components: {
    Subtitle: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Subtitle" */ '@/modules/cms/components/Subtitle.vue'
      )
    ),

    Message: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Message" */ '@/components/Message.vue')
    ),
  },
  setup() {
    return {
      ...useRecipeState(),
    };
  },
};
</script>
